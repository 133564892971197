const key = generateUUID();
var uuidInput;
var loaderElemnt;


shadowRoot =
    (document.querySelector(`[flow-id="trip-planning-request"]`) &&
        document.querySelector(`[flow-id="trip-planning-request"]`)
        .shadowRoot) || document;
    
loaderLoading(true);
fillUUID()


document.addEventListener('DOMContentLoaded', () => {
    console.log('DOMContentLoaded');
    fillUUID()
    loaderLoading(true);
});

function fillUUID(){
    console.log('fill uuid');
    uuidInput=shadowRoot.getElementById('uuid');
    if(uuidInput){
        uuidInput.value = key;
    }else {
         console.log('cannot select element with id');
    }
}

function loaderLoading(loading){
    if (loading){
      //  shadowRoot.getElementById('loader-e3f10c06').classList.remove('circle-loader-load-success');
    }else {
     //    shadowRoot.getElementById('loader-e3f10c06').classList.add('circle-loader-load-success');
    }
}

window.addEventListener('heyflow-screen-view', (event) => {
  // console.log('heyflow screen view:', event.detail);
  fillUUID()
});

window.addEventListener('heyflow-submit', (event) => {
  // console.log('heyflow submit:', event.detail);
  // console.log('key is:'+key)
    loaderLoading(true);
    fillUUID()
    callAnswer(key,5000);
});

var requestOptions = {
  method: 'GET',
  redirect: 'follow'
 };
 
var answerExist=false;
var answerFetchContinue=true;
function callAnswer(uuid,after){
  setTimeout(function(){
      var tryCall=0;
      setInterval(function(){
         
         if(tryCall>=10){
            return;
         }
         
         if(answerFetchContinue){
             loaderLoading(true);
               
             fetch("https://api.thinkautomation.com/addmessage?taid=64aab211e5b71256fcff4e7364b53bd8e5b7120efc2181fd8RzDfJVFNPDvQk2P%2bniCj9jH%2fhYf%2fkyD&results=true&x-uuid="+uuid, requestOptions)
                  .then(response => response.text())
                  .then(result => {
                    // console.log(result);
                     answerApplyer(result)
                  })
            .catch(error => console.log('error', error));
         }else{
             return;
         }
         tryCall++;
      },7000)
      
  },after);
}

function answerApplyer(mes_response){
  if (mes_response) {
        answerExist=true;
        answerFetchContinue=false;
        loaderLoading(false);
        shadowRoot.getElementById('answer').innerHTML = clearMessage(mes_response);
   
      shadowRoot.getElementById('loader-e3f10c06').classList.add('circle-loader-load-success');
  }
}

function clearMessage(message){
  return message.replace('\n','')
      .replace("\\",'')
}


function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
     const r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8);
     return v.toString(16);
  });
 }
